<template>
  <div class="consensu">
    <template v-if="selected && result">
      <component
        :is="selected"
        :consent="result.consent"
        :version="result.version"
      ></component>
    </template>
  </div>
</template>
<script>
  import api from "../mixins/api";
  export default {
    name: "Home",
    mixins: [api],
    created() {
      this.getConsent();
    },
    data() {
      return {
        result: false,
        selected: false,
        whitelist: ["box", "modal", "sidebar"],
      };
    },
    methods: {
      load(cmp) {
        return () => import(`../components/${cmp}`);
      },
      getConsent() {
        this.api.get("/v1/process/iframe").then((result) => {
          // result.data.consent.caller = "bar";
          // result.data.consent.configs.position = "bottom-center";
          this.result = result.data;
          // result.data.consent.configs.type = "modal";
          this.selected = this.load(result.data.consent.configs.type);
        });
      },
    },
    computed: {
      // selected() {
      //   return true
      //   // if (this.whitelist.indexOf(this.$route.params.type) > 0) {
      //   //   return this.load(this.$route.params.type);
      //   // } else {
      //   //   return this.load("box");
      //   // }
      // },
    },
  };
</script>
