import axios from "axios";

const API = {
  computed: {
    api() {
      let instance = axios.create({
        baseURL: process.env.VUE_APP_API_CONSENSU,
        timeout: 10000,
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`,
        },
      });
      return instance;
    },
  },
};
export default API;
